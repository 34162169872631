// navbar
.navbar {
  flex-wrap: nowrap;

  &-nav {
    .nav-link {
      color: var(--foreground);
      font-size: #{op.$font-size-fluid-1};

      &:hover {
        color: var(--foreground-higher);
      }
    }

    .nav-item {
      &.is_active > .nav-link {
        font-weight: 700;
        color: var(--foreground-higher);
      }

      .offcanvas.show & {
        margin-left: -0.5rem;
        padding-left: 0.5rem;
      }
    }
  }

  &-toggler {
    border-color: transparent;

    &:focus,
    &:active {
      outline: 0;
      box-shadow: none;
    }
  }

  &-brand {
    --link-higher: var(--link);

    margin-bottom: 0;
    padding: 0;
    max-width: 10rem;
    display: flex;
    align-items: center;
    width: 10rem;
    overflow: hidden;

    svg {
      width: 10rem;  // Safari hack
    }
  }
}

[data-theme="light"] {
  .navbar-custom {
    @extend .navbar-light;
  }
}

[data-theme="dark"] {
  .navbar-custom {
    @extend .navbar-dark;
  }
}
