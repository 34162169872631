html, body {
  height: 100%;
}

body {
  display: grid;
  grid-template-rows: auto 1fr auto;
  scrollbar-gutter: stable;
  height: 100%;

  & > header,
  & > main {
    margin-block-end: var(--gap);
  }

  & > header,
  & > footer,
  & > main {
    @include grid;

    gap: 0;
    align-content: flex-start;
  }

  & > main {
    > * {
      grid-column: content;
    }
  }

  // Header, footer wider
  & > header,
  & > footer {
    & > * {
      grid-column: feature;
      gap: 1rem;
    }

    padding-block: 1rem;
  }
}

// template-specific
.template {
  // Work
  // &-workpage {}

  // Work index
  &-workpage--index {
    main > section {
      display: grid;
      gap: var(--gap);

      @include media-breakpoint-up(md) {
        grid-template-columns: minmax(var(--col), 1fr) min-content;

        aside {
          order: 1;
        }
      }

      > div {
        display: grid;
        gap: 1rem;
        order: 1;

        @include gridAuto;

        h2 {
          margin-bottom: 0;
        }

        article {
          position: relative;;

          main {
            a {
              @extend .stretched-link;
            }
          }
        }
      }

      h1 {
        @include grid-reset;
      }
    }
  }

  // Home
  &-homepage {
    .featured-works > div {
      @include grid;
      @include gridAuto;

      article {
        position: relative;
      }
    }
  }

  // Object
  &-objectpage {
    main  {
      > .block {
        &-image {
          grid-column: feature;
        }
      }
    }

    carou-scroll {
      grid-column: feature;
    }
  }

  // Person
  &-personpage {
    main {
      > aside {
        grid-column: feature;
      }

      > section {
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: var(--gap);
        margin-block-end: var(--gap);
      }
    }
  }

  &-personindexpage {
    .person {
      position: relative;
    }
  }
}

// Content list
.content {
  &__list {
    grid-column: feature;
    display: grid;

    @include gridAutoFit;

    gap: 1rem;

    --col: 12rem;

    h2, h3 {
      @include grid-reset;
    }

    article {
      @include flex;
      @include flexColumn;

      gap: 0.5rem;
      position: relative;

      header {
        max-inline-size: max-content;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        // thumbnail
        img {
          border: 1px solid var(--bs-border-color);

          // default_image logo.svg with padding
          &[src$=".svg"] {
            padding: 0.2rem;
          }
        }
      }
    }
  }
}

// Force square images in lists
.img-sq {
  aspect-ratio: 1;
}
