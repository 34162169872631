@use 'sass:color';

// Wagtail blocks
.block {
  &-document_block {
    margin-block-end: var(--gap);
  }

  // page block
  &-page_block {
    display: block;
    margin-block: var(--gap);
  }

  // image block
  &-image_block {
    margin-inline: auto;
    max-inline-size: max-content;
    margin-block-end: var(--gap);
  }

  // AV

  &-media {
    audio,
    video {
      width: 100%;
      height: auto;
    }
  }

  &--image_grid_block {
    ul {
      @include flex;

      list-style-type: "";
      margin-block: var(--gap);
      padding: unset;
      gap: var(--gap);
      align-items: center;

      figcaption {
        display: none;
      }
    }

    // grayscale, invert images in dark theme
    // XXX invert doesn't invert first grayscaled filter
   
    // img {
    //   [data-theme="dark"] & {
    //     filter: grayscale(1);
    //     filter: invert(1);
    //   }

    //   @media (prefers-color-scheme: dark) {
    //     filter: grayscale(1);
    //     filter: invert(1);
    //   }
    // }
  }
}
