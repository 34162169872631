// https://github.com/zachleat/carouscroll
// https://zachleat.github.io/carouscroll/demo.html
carou-scroll {
  display: flex;
  overflow: hidden;
  scroll-snap-type: x mandatory;

  & > * {
    min-width: 100%;
    scroll-snap-align: center;
    aspect-ratio: 16/9;
  }
}

.carouscroll-meta {
  grid-column: feature;
  margin-block-end: var(--gap);
  align-items: center;

  .icon {
    stroke: var(--foreground);
  }
}
