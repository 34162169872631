body {
  & > footer {
    background-color: var(--background-lower);
  
    > nav {
      @include flex;
  
      flex-wrap: wrap;
      gap: 0.5rem;
  
      button {
        margin-inline-start: auto;
      }
    }
  
    & > div {
      // logos
      @include flex;

      grid-column: content;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 1rem;
      margin-block: 1rem;
      justify-self: center;
      align-items: center;
  
      a {
        text-decoration: none;
        display: inline-block;
      }

      p {
        text-align: justify;
        font-size: smaller;
      }
    }
  }
}