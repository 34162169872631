// https://open-props.style/
// open props as scss variables
// https://github.com/mayank99/open-props-scss

:root {
  // Grid
  --gap: clamp(1rem, 5vw, 3rem);
  --col: 12rem;
  --accent: #{op.$gray-7};
  --border-width: 0.1rem;

  color-scheme: light dark;
  accent-color: var(--accent);

  // https://enzedonline.com/en/tech-blog/create-responsive-font-sizes-based-on-the-viewport/
  --font-size-body: font-size: clamp(1rem, 0.7859rem + 0.9014vw, 1.8rem);
}

// colors
html,
html[data-theme="light"] {
    --border-color: #{op.$gray-2};
    --link: #{op.$indigo-8};
    --link-higher: #{op.$indigo-10};
    --background: #{op.$gray-0};
    --foreground: #{op.$gray-8};
    --middleground: #{op.$gray-6};
    --background-lower: #{op.$gray-1};
    --background-higher: var(--bs-white);
    --foreground-higher: #{op.$gray-9};
    --foreground-lower: #{op.$gray-7};
    --shadow-color: #{op.$gray-5};
    --background-transparent: rgb(206 212 218 / 70%);

    $navbar-light-color: rgb(var(--forground) 0.55);
    $navbar-light-hover-color: rgb(var(--forground) 0.75);
    $navbar-light-active-color: var(--bs-white);
    $navbar-light-disabled-color: rgb(var(--forground) 0.25);
  }

@media (prefers-color-scheme: dark) {
  html:not([data-theme]) {
    color-scheme: dark;
  }

  :root  {
    --border-color: #{op.$gray-7};
    --link: #{op.$indigo-3};
    --link-higher: #{op.$indigo-1};
    --background: #{op.$gray-8};
    --foreground: #{op.$gray-1};
    --middleground: #{op.$gray-5};
    --background-higher: #{op.$gray-7};
    --background-lower: #{op.$gray-9};
    --foreground-higher: var(--bs-white);
    --foreground-lower: #{op.$gray-1};
    --shadow-color: #{op.$indigo-7};
    --background-transparent: rgb(73 80 87 / 70%);
  }

  $navbar-dark-color: rgb(var(--foreground) 0.55);
  $navbar-dark-hover-color: rgb(var(--foreground) 0.75);
  $navbar-dark-active-color: var(--foreground);
  $navbar-dark-disabled-color: rgb(var(--foreground) 0.25);
  $navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
  $navbar-dark-toggler-border-color: rgb(var(--foreground) 0.1);
}

[data-theme="light"] {
  color-scheme: light;

  --border-color: #{op.$gray-2};
  --link: #{op.$indigo-8};
  --link-higher: #{op.$indigo-10};
  --background: #{op.$gray-0};
  --foreground: #{op.$gray-8};
  --middleground: #{op.$gray-6};
  --background-lower: #{op.$gray-1};
  --background-higher: var(--bs-white);
  --foreground-higher: #{op.$gray-9};
  --foreground-lower: #{op.$gray-7};
  --shadow-color: #{op.$gray-5};
  --background-transparent: rgb(206 212 218 / 70%);
}

[data-theme="dark"] {
  color-scheme: dark;

  --border-color: #{op.$gray-7};
  --link: #{op.$indigo-3};
  --link-higher: #{op.$indigo-1};
  --background: #{op.$gray-8};
  --foreground: #{op.$gray-2};
  --middleground: #{op.$gray-5};
  --background-lower: #{op.$gray-9};
  --background-higher: #{op.$gray-7};
  --foreground-higher: var(--bs-white);
  --foreground-lower: #{op.$gray-1};
  --shadow-color: #{op.$indigo-7};
  --background-transparent: rgb(73 80 87 / 70%);
}

// Bootstrap overrides
// $primary: #0060df;
$breadcrumb-active-color: var(--foreground-lower);
$breadcrumb-divider-color: var(--border-color);
$btn-white-space: nowrap;
$carousel-control-color: var(--link);
$card-bg: var(--background);
$card-header-bg: var(--background);
$offcanvas-color: var(--foreground);
$offcanvas-bg-color: var(--background-higher);
$transition-time: 0.6s;

// navbar
$navbar-light-color: var(--foreground-lower);
$navbar-dark-color: var(--foreground-lower);
$navbar-light-hover-color: var(--foreground-higher);
$navbar-dark-hover-color: var(--foreground-higher);

// pagination
$pagination-active-border-color: unset;
$pagination-active-bg: var(--accent);
$pagination-bg: var(--background);
$pagination-color: var(--foreground);
$pagination-disabled-bg: var(--background);
$pagination-disabled-color: var(--foreground-lower);

// SVG nav buttons
$btn-close-color: #ccc;
