body {
  color: var(--foreground);
  background: var(--background);
}

a {
  color: var(--link);

  &:hover {
    color: var(--link-higher);
  }
}
