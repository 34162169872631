body {
  & > footer {
    fieldset {
      padding: 0;
      border: none;
      margin-inline-start: auto;

      label {
        cursor: pointer;
      }
    }
  }
}
