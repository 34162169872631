img {
  @include img-fluid;

  max-height: unset;
}

picture {
  @include flexColumn;

  gap: calc(var(--gap) * 0.1);
  justify-content: center;
}

figcaption {
  color: var(--middleground);
  font-size: 90%;
}
