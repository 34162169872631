.search {
  display: grid;
  grid-template-areas:
    "model ."
    "term submit"
    "filter .";
  grid-template-columns: 1fr min-content;
  gap: 1rem 0.5rem;

  .form-group {
    &:first-child {
      grid-area: model;

      @include flex;

      align-items: center;
      gap: 1rem;

      div {
        @include flex;

        gap: 0.3rem;

        &:first-of-type {
          gap: 1rem;
        }

        label {
          margin-bottom: 0;
        }
      }

      > label {
        display: inline-block;
        margin-bottom: 0;
      }
    }

    &:nth-child(2) {
      grid-area: term;

      label {
        @extend .visually-hidden;
      }
    }
  }

  button[type="submit"] {
    grid-area: submit;
  }

  aside {
    grid-area: filter;
    margin-block-end: var(--gap);
  }


  &__results {
    li {
      margin-bottom: 1.5rem;
    }
  }
}
