// 1: https://ryanfeigenbaum.com/easy-responsive-typography/
// 2: https://ryanfeigenbaum.com/fluid-typography/
// https://royalfig.github.io/fluid-typography-calculator/
// 3: https://css-tricks.com/consistent-fluidly-scaling-type-and-spacing/
// 4: https://www.smashingmagazine.com/2022/10/fluid-typography-clamp-sass-functions/

// Work Sans variable font
// https://fontsource.org/fonts/work-sans/install
@include WorkSans.faces(
  $subsets: (
    latin,
    latin-ext,
  ),
  $weights: (
    400,
    500,
    600,
    700,
  ),
  $styles: all,
  $directory: "../font/"
);

body {
  font-family: $font-family-base;
  font-size: var(--font-size-body);
  font-variant-ligatures: common-ligatures;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-optical-sizing: auto;

  @media (prefers-color-scheme: dark) {
    font-weight: 350;
  }
}



h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Work Sans Variable", $font-family-base;
  font-weight: 600;
  hyphens: auto;

  @supports (font-variation-settings: normal) {
    & {
      font-variation-settings: "wght" 600;
      font-feature-settings: "calt", "kern", "dlig";
      font-synthesis: none;
    }
  }

  > a {
    text-decoration: none;
  }
}

p {
  hyphens: auto;
}

em,
i {
  font-style: italic;
  font-variation-settings: "ital";
}

b,
strong {
  font-weight: 600;
  font-variation-settings: "wght" 600;
}

code {
  color: currentcolor;
}

h4, h5 {
  text-wrap: balance; // Balance
}

h1 {
  font-size: clamp(2rem,9vw,2.2rem);
  margin-block-end: 1rem;
}

h2 {
  font-size: clamp(1.3rem,6vw,1.5rem);
  margin-block-end: 0.75rem;
}

h3 {
  font-size: #{op.$font-size-fluid-1};
  margin-block-end: 0.5rem;
}
