/* icons */
.icon {
  display: inline-block;
  vertical-align: middle;
  width: #{op.$size-3}; // 1rem
  height: #{op.$size-3};
  stroke: currentcolor;
  fill: none;
  margin-top: -0.25rem;

  .disabled a & {
    stroke: var(--middleground);
  }

  // Wagtail Userbar icon nicht geändert
  &.wagtail-action-icon {
    fill: unset;
    stroke: transparent;
  }

  // Sizes
  &--ssm {
    width: #{op.$size-4}; // 1.25rem
    height: #{op.$size-4}; // 1.25rem
  }

  &--sm {
    width: #{op.$size-5}; // 1.5rem
    height: #{op.$size-5}; // 1.5rem
  }

  &--md {
    width: #{op.$size-6}; // 1.75rem
    height: #{op.$size-6}; // 1.75rem
  }

  &--lg {
    width: #{op.$size-7}; // 2rem
    height: #{op.$size-7}; // 2rem
  }

  &--xl {
    width: #{op.$size-8}; // 3rem
    height: #{op.$size-8}; // 3rem
  }

  // PhotoSwipe toolbar
  &.pswp__icn {
    width: 24px;
    height: 24px;
    fill: none;
    stroke: var(--pswp-icon-color);
    top: 18px;
    margin-top: unset;
  }

  // mark current checked theme in footer
  label:has(input:checked) & {
    fill: #{op.$gray-4};
  }
}
